    @import "styles/variables";
    @import "styles/mixins";

.divider {
    margin: $spacing-5 0;
}

.line {
    width: 100%;
    height: 1px;
    background-color: $color-borders;
}
